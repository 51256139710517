body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #25292F; /* Matches the header background */
  color: #00E0FF; /* Primary text color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
}

/* Ensure links are styled properly */
a {
  color: #00E0FF;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #00b3cc;
}

/* Add some basic styling for buttons */
button {
  background-color: #00E0FF;
  color: #25292F; /* Matches the body background */
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #00b3cc;
}

/* Ensure images are responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Basic container styling for padding and centering */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

/* Utility classes for margins and padding */
.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* Responsive text sizes */
.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-5xl {
  font-size: 3rem;
}

/* Additional utility classes for consistency */
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.text-center {
  text-align: center;
}

/* Hero section styling */
.hero {
  margin-top: 4rem;
  text-align: center;
  padding: 2rem 1.5rem;
  background: linear-gradient(to right, #00E0FF, #00b3cc); /* Matches the hero section in Header.css */
  color: #25292F;
}

.hero h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.hero button {
  background-color: #00E0FF;
  color: #25292F;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hero button:hover {
  background-color: #00b3cc;
}
