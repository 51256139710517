.features {
  background-color: #25292F;
  color: #00E0FF;
}

.feature {
  max-width: 300px;
  text-align: left;
  margin: 0 auto;
}

.feature h2 {
  font-size: 1.5em;
}

.feature p {
  font-size: 1em;
}
