.footer {
  background-color: #25292F;
  color: #00E0FF;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links a {
  color: #00E0FF;
}
