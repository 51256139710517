.header {
  background-color: #25292F;
  color: #00E0FF;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: auto;  /* Ensure logo takes its natural width */
  height: 100px; /* Adjust height as needed */
  margin-right: 1rem;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: #00E0FF;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1em;
  transition: color 0.3s;
  padding: 0.5rem 0;
  position: relative;
}

.nav-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00E0FF;
  transition: width 0.3s;
}

.nav-links a:hover::after {
  width: 100%;
}

.nav-links a:hover {
  color: #ffffff;
}

.hero {
  margin-top: 4rem;
  text-align: center;
  padding: 2rem 1.5rem;
}

.hero h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.hero button {
  background-color: #00E0FF;
  color: #25292F;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hero button:hover {
  background-color: #00b3cc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-container {
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
  }

  .logo {
    height: 60px; 
    margin-right: 0;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
  }

  .nav-links a {
    padding: 0.5rem;
    width: 100%;
    text-align: left;
  }

  .hero {
    padding: 1rem;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }
}
